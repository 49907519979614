.marker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker.center {
  transform: translate(-50%, -100%);
}

.marker-icon {
  font-size: 2rem;
}

.provider-marker {
  color: blue;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.provider-marker.provider-selected {
  color: green;
}

.provider-marker.focused svg {
  transform-origin: bottom center;
  scale: 1.5;
  animation: wiggle 1s linear infinite;
}

@keyframes wiggle {
  from {
    rotate: 0deg;
  }

  25% {
    rotate: -25deg;
  }

  75% {
    rotate: 25deg;
  }

  to {
    rotate: 0deg;
  }
}

.cluster-marker {
  background-color: rgba(0, 0, 255, 0.6);
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.you-marker {
  color: red;
}

.marker-text {
  color: white;
  text-align: center;
}
