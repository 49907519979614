.legal-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.legal-text {
  background-color: var(--secondary-background-color);
  padding: 2rem 1rem;
}

.legal-text-item {
  padding: 0.5rem 0;
}

.legal-terms-link {
  color: var(--secondary-color);
}
