.provider-description-container {
  margin-bottom: 1.5rem;
}

.provider-description-paragraph {
  padding: 0.2rem 0;
  font-size: 0.948rem;
}

.provider-description-paragraph.bold {
  font-weight: bold;
  font-size: 1.15rem;
}
