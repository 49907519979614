.child-dd-container {
  margin: 1rem 1rem 1rem 0;
}

.bday-section-dropdown-container {
  display: flex;
  align-items: center;
}

.validation-error-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Open Sans', serif;
  margin-top: 2rem;
}

.err-msg {
  color: #ff0000;
  margin-left: 0.25rem;
}

.here-link {
  color: var(--secondary-color);
  text-decoration: none;
}

.pink-section {
  padding: 1.5rem 0;
  margin: 1rem 0;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.margin-top {
  margin-top: 1rem;
}
