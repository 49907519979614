.back-to-screen-message {
  background-color: var(--secondary-background-color);
  border: 1px solid black;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
}

.back-to-screen-warning-icon {
  min-width: 2rem;
  min-height: 2rem;
  width: 2rem;
  height: 2rem;
}

.back-to-screen-warning-icon path {
  fill: red;
}
