.back-continue-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: -0.5rem;
}

.logo-container {
  display: flex;
  justify-content: center;
}

.adams-landing-pg-logo {
  width: 100%;
  max-width: 20rem;
}

.adams-landing-pg-article {
  margin: 4rem 0 1rem 0;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.adams-landing-pg-article h1 {
  text-align: center;
  font-size: 1.8rem;
}

.adams-landing-pg-article .subheader {
  text-align: center;
}

.adams-landing-pg-article h2 {
  font-size: 1.2rem;
}

.pink-background {
  background-color: #fbf9fc;
  padding: 2rem;
}

.landing-closed-warning {
  color: var(--secondary-color);
  font-style: italic;
}
