.final-page-container {
  font-size: 1.3rem;
}

.final-page-container h1 {
  text-align: center;
  padding: 0 0 2rem 0;
}

.final-page-container p {
  margin-bottom: 1rem;
}
