.address-input-container {
  width: 40rem;
  max-width: 100%;
}

.address-error-list li {
  padding: 0.25rem 0;
}

.address-error-list a {
  color: var(--secondary-color);
}
