.provider-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.provider-group-container {
  display: flex;
  align-items: center;
}

svg.provider-filter-icon {
  fill: var(--midBlue-color);
  font-size: 2rem;
}

.provider-filter-button {
  background-color: white;
  border: 1px solid black;
  border-radius: 999rem;
  font-size: 1.2rem;
  margin: 0 0.5rem;
  width: 8rem;
  display: inline-flex;
  justify-content: space-between;
  padding: 0.1rem 0.4rem;
}

.provider-filter-button.changed {
  background-color: #e4e6ef;
}

svg.provider-filter-button-icon {
  transition: transform 0.5s;
}

.provider-filter-button-icon.open {
  transform: rotate(90deg);
}

.provider-filter-popup-relative-container {
  position: relative;
  height: 2rem;
}

.provider-filter-popup-hidden-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000030;
  z-index: 998;
}

.provider-filter-popup-container {
  position: absolute;
  background-color: white;
  border: 1px solid black;
  border-radius: 2rem;
  padding: 1rem;
  z-index: 999;
  top: 100%;
  left: 0;
  width: 20rem;
}

.provder-filters-reset {
  border: none;
  background-color: transparent;
  color: var(--midBlue-color);
  font-size: 1.1rem;
  cursor: pointer;
}

@media (max-width: 900px) {
  .provider-filter-container {
    position: relative;
    flex-direction: column;
    align-items: baseline;
    gap: 1rem;
  }

  .provider-filter-popup-relative-container {
    position: absolute;
  }
}
