* {
  box-sizing: border-box;
  margin: 0%;
}

#root {
  overflow-x: hidden;
  position: relative;
}

.benefits-form {
  margin: 0 auto 20px auto;
  justify-content: center;
  padding: 1rem;
  max-width: var(--main-max-width);
}

@media only screen and (max-device-width: 600px) {
  .hidden-xs {
    display: none !important;
  }
}

.step-progress-title {
  font-weight: 700;
  color: var(--primary-color);
}

.sub-header {
  font-size: 2rem;
  font-weight: 700;
  line-height: normal;
  font-family: 'Roboto Slab', serif;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.main-paragraph {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 1.1rem;
}

.sub-header-language-select {
  font-family: 'Open Sans', sans-serif;
  color: black;
  margin-bottom: 1rem;
}

.bottom-border {
  border-bottom: 1px solid #2a2b2a;
  margin-bottom: 1rem;
}

.MuiDataGrid-columnHeaderTitle {
  overflow: visible !important;
  line-height: 1.43rem !important;
  white-space: normal !important;
}

/* text input label in a focus state */
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
  color: var(--primary-color);
}

/* text input outline in a focus state */
.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused
  .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color);
}

/* text input outline in an error state */
/* .MuiOutlinedInput-root.MuiInputBase-formControl.Mui-error .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
} */

/* text input outline in a default state */
/* .MuiOutlinedInput-root.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
  border-color: #037A93;
} */

.subheader-desc {
  font-weight: 400;
  font-size: 1rem;
}

.sign-up-data-privacy-link {
  color: var(--primary-color);
}

.main-max-width {
  max-width: var(--main-max-width);
  margin: 0 auto -5rem;
  min-height: calc(100vh - 5rem);
}

.push {
  height: 5rem;
}
.secondary-heading {
  font-family: 'Open Sans', sans-serif;
  color: black;
}

/* the following section classes are needed in order to extend the background to the edges of the screen */
.section-container {
  position: relative;
}

.section:before {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: -100vw;
  width: 300vw;
  background: var(--secondary-background-color);
  z-index: -1;
}

.loading-image {
  height: 150px;
  width: 150px;
  animation: rotate-s-loader 1s linear infinite;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
