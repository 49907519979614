.provider-container {
  display: flex;
  height: 90vh;
}

@media (max-width: 1000px) {
  .provider-container {
    flex-direction: column-reverse;
    height: 70rem;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
}
