.map-container {
  width: 100%;
  height: 100%;
}

@media (max-width: 1000px) {
  .map-container {
    height: 40rem;
  }
}
