.provider-cards-container {
  background-color: var(--secondary-background-color);
  height: 100%;
  max-height: 100rem;
  overflow-y: scroll;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;
}

.provider-cards-title {
  font-size: 2rem;
}

.provider-card-container {
  background-color: white;
  border: 1px solid black;
  padding: 0.5rem;
  margin: 0.2rem;
  position: relative;
}

.provider-card-container.focused {
  border: 0.2rem solid var(--secondary-color);
  margin: 1px;
}

.provider-card-header {
  font-weight: bold;
  font-size: 1.2rem;
  padding-right: 2rem;
}

.provider-card-marker {
  position: absolute;
  right: 0;
}

.provider-card-address {
  font-size: 0.8em;
  display: flex;
}

.provider-card-detail-contianer {
  padding: 1rem 0;
  font-size: 0.8rem;
}

svg.provider-card-star.provider-card-star-full {
  fill: #efce4a;
}

svg.provider-card-star {
  font-size: 1rem;
  transform: translate(0, 0.2rem);
}

.provider-card-disclaimer {
  font-size: 0.8rem;
  padding-bottom: 1rem;
}

.providerCard-selectButtons-title-contianer {
  padding: 0.5rem 0;
}

.provider-card-button-container {
  margin-bottom: 0.2rem;
}

.provider-card-select-child-button {
  background-color: white;
  padding: 0.2rem 0.5rem;
  border: 1px solid black;
  border-radius: 999rem;
  cursor: pointer;
}

.provider-card-select-child-button.selected {
  background-color: #d6e3da;
}

.provider-cards-page-navigation-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--secondary-color);
  font-size: 1.2rem;
  margin: 1px;
}

.provider-cards-page-navigation-button.selected {
  color: black;
  border: 1px solid black;
  border-radius: 0.5rem;
  margin: 0;
}

.provider-cards-page-navigation-container {
  text-align: center;
  padding: 1rem;
}

@media (min-width: 1000px) {
  .provider-card-container {
    max-width: 40rem;
  }

  .provider-cards-container {
    min-width: 30rem;
  }
}
