.providers-child-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}

.providers-child-card-description-contianer {
  padding-bottom: 0.75rem;
  margin-bottom: 0.5rem;
  border-bottom: 0.15em solid var(--midBlue-color);
}

.providers-child-card-description {
  font-size: 0.79em;
}

.providers-child-card-child-continue-link {
  background-color: transparent;
  border: none;
  color: var(--secondary-color);
  font-size: 1em;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.providers-child-card-container {
  font-size: 1.2em;
  border: calc(0.1em + 1px) solid var(--midBlue-color);
  padding: 0.7em;
  margin: 1px;
  width: 100%;
  border-radius: 1em;
}

.providers-child-card-container.error {
  border: calc(0.15em + 2px) solid red;
  margin: 0;
}

.providers-child-card-child-name {
  font-size: 1.05em;
  font-family: 'Roboto Slab', serif;
  color: var(--primary-color);
}

.providers-child-card-provider-item {
  padding: 1px;
  display: flex;
  justify-content: space-between;
}

.provider-child-card-provider-name {
  font-size: 0.9em;
  background-color: transparent;
  border: none;
  padding: none;
  cursor: pointer;
  font-weight: bold;
}

.provider-child-card-provider-name:hover {
  text-decoration: underline;
}

.providers-child-card-provider-item.focused {
  border: 1px solid var(--secondary-color);
  border-radius: 999rem;
  padding: 0.1em;
}

.providers-child-card-provider-button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  font-size: 0.9em;
  margin: 0 0.5em;
}

.providers-child-card-provider-button.view {
  color: #3c7e3a;
}

.providers-child-card-provider-button.close {
  color: #9f0404;
}

.provider-child-card-reset-button {
  border: none;
  background-color: transparent;
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .providers-child-container {
    flex-direction: column;
  }
}
